body {
  height: 100%;
  font-family: "Lato","Noto Sans JP",BlinkMacSystemFont,-apple-system,"Segoe UI",Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #4a4a4a;
}

p {
  margin-bottom: .5rem;
}

a {
  &:hover {
    text-decoration: underline;
  }
}

a.btn-wrapper {
  &:hover {
    text-decoration: none;
  }
}

button.is-white {
  color: #4a4a4a !important;
}


.footer {
  background-color: $light;
  .socials {
    margin: 0 auto 1rem auto;

    a {
      margin: 0 0 0 .5rem;
    }

    svg {
      color: $primary;
    }
  }

  .footer-links {
    margin: 0 0 1rem 0;
    .footer-link {
      margin: 0 0 0 .5rem;
    }
  }
}

h1 { font-size: 1.8rem; }
h2 { font-size: 1.5rem; }
h3 { font-size: 1.3rem; }
h4 { font-size: 1.2rem; }
h5 { font-size: 1.1rem; }
h6 { font-size: 1.0rem; }

@media screen and (max-width: 1050px) {
  .iframe-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 75%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.sns-share-list {
  list-style:none;
  display: flex;
  flex-wrap:wrap;
  padding:0;

  .sns-share-list__item {
    flex: 1;
    display: block;
    text-align:center;

    .sns-share-list__link {
      padding: 1rem 0;
      display:block;
      color:#ffffff;
      text-decoration: none;

      &::before {
        font-size:20px;
        display:block;
        transition: ease-in-out .2s;
      }

      &:hover::before {
        background:#ffffff;
        transform: scale(1.2);
        box-shadow:1px 1px 4px 0px rgba(0,0,0,0.15);
      }

      &.icon-twitter {
        background:#55acee !important;

        &:hover::before{
          color:#55acee;
        }
      }

      &.icon-facebook {
        background:#3B5998 !important;

        &:hover::before {
          color:#3B5998;
        }
      }

      &.icon-google-plus {
        background:#dd4b39 !important;

        &:hover::before {
          color:#dd4b39;
        }
      }
    }
    
  }
}
