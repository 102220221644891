@each $direction in top, right, bottom, left {
	@include mobile {
		.has-tooltip-#{$direction}-mobile {
			@include tooltip-direction(#{$direction}); } }
	@include tablet {
		.has-tooltip-#{$direction}-tablet {
			@include tooltip-direction(#{$direction}); } }
	@include tablet-only {
		.has-tooltip-#{$direction}-tablet-only {
			@include tooltip-direction(#{$direction}); } }
	@include touch {
		.has-tooltip-#{$direction}-touch {
			@include tooltip-direction(#{$direction}); } }
	@include desktop {
		.has-tooltip-#{$direction}-desktop {
			@include tooltip-direction(#{$direction}); } }
	@include desktop-only {
		.has-tooltip-#{$direction}-desktop-only {
			@include tooltip-direction(#{$direction}); } }
	@include until-widescreen {
		.has-tooltip-#{$direction}-until-widescreen {
			@include tooltip-direction(#{$direction}); } }
	@include widescreen {
		.has-tooltip-#{$direction}-widescreen {
			@include tooltip-direction(#{$direction}); } }
	@include widescreen-only {
		.has-tooltip-#{$direction}-widescreen-only {
			@include tooltip-direction(#{$direction}); } }
	@include until-fullhd {
		.has-tooltip-#{$direction}-until-fullhd {
			@include tooltip-direction(#{$direction}); } }
	@include fullhd {
		.has-tooltip-#{$direction}-fullhd {
			@include tooltip-direction(#{$direction}); } } }
