.navbar{
  background-color: transparent;
  color: $white;
  height: 5.0rem;
  a { color: $white }
  .navbar-brand {
    flex-shrink: 1;
    .navbar-item {
      display: inline-block;
      width: 100%;
      font-weight: bolder;
      img {
        max-width: 100%;
        height: 100%;
        max-height: none;
      }
    }
  }
  .navbar-item {
    font-size: 1.1rem;
    padding: 1rem;

    &:hover {
      background-color: rgba(250,250,250,0.1);
      color: $white;
    }
  }

  &.is-colored {
    background-color: $primary;
    color: #fff;
    a.navbar-item {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    .navbar-item {
      .is-primary {
        background-color: white;
        color:#4a4a4a;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .navbar-item {
      font-size: 1.2rem;
      padding: 1rem;
    }

    .navbar-brand {
      flex-shrink: 1;
      .navbar-item {
        max-width: 15rem;
        display: inline-block;
        width: 100%;
        font-weight: bolder;
        img {
          max-width: 100%;
          height: auto;
          max-height: none;
        }
      }
    }

    #navBurger {
      .navbar-item {
        color: $black;
      }
    }

    &.is-colored {
      background-color: $primary;
	     a.navbar-item {color:#000;
	      &:hover {
	      color:#000;
      }
    }
    }
  }

  @media screen and (max-width: 768px) {
    height: 5.0rem;

    .navbar-item {
      font-size: 1.2rem;
      padding: 1rem;
    }

    .navbar-brand {
      flex-shrink: 1;
      .navbar-item {
        max-width: 15rem;
        display: inline-block;
        width: 100%;
        font-weight: bolder;
        img {
          max-width: 100%;
          height: auto;
          max-height: none;
        }
      }
    }

    #navBurger {
      .navbar-item {
        color: $black;
      }
    }

    &.is-colored {
      background-color: $primary;
	     a.navbar-item {color:#000;
	      &:hover {
	      color:#000;
        }
      }
    }
  }
}
