@import 'variables';
@import 'position';
@import 'animation';

@mixin tooltip-arrow {
	&::after {
		content: '';
		border-style: solid;
		border-width: $tooltip-arrow-size;
		border-color: rgba($tooltip-background-color, $tooltip-background-opacity) transparent transparent transparent;
		margin-bottom: $tooltip-arrow-size * -1 + 1; }
	@include tooltip-arrow-top; }

@mixin tooltip-box {
	&::before {
		background: rgba($tooltip-background-color, $tooltip-background-opacity);
		border-radius: $tooltip-radius;
		content: attr(data-tooltip);
		padding: $tooltip-padding;
		text-overflow: ellipsis;
		white-space: pre; }
	@include tooltip-box-top; }

@mixin tooltip-multiline {
	&::before {
		height: auto;
		width: $tooltip-max-width;
		max-width: $tooltip-max-width;
		text-overflow: clip;
		white-space: normal;
		word-break: keep-all; } }

[data-tooltip] {
	&:not(.is-loading),
	&:not(.is-disabled),
	&:not([disabled]) {
		cursor: pointer;
		overflow: visible;
		position: relative;

		&::before,
		&::after {
			box-sizing: border-box;
			color: $tooltip-color;
			display: inline-block;
			font-family: $tooltip-font-family;
			font-size: $tooltip-font-size;
			hyphens: auto;
			opacity: 0;
			overflow: hidden;
			pointer-events: none;
			position: absolute;
			visibility: hidden;
			z-index: $tooltip-z-index; }

		@include tooltip-arrow;
		@include tooltip-box;

		&.has-tooltip-bottom {
			@include tooltip-direction('bottom'); }

		&.has-tooltip-left {
			@include tooltip-direction('left'); }

		&.has-tooltip-right {
			@include tooltip-direction('right'); }

		&.has-tooltip-multiline {
			@include tooltip-multiline; }

		@each $name, $pair in $colors {
			$color: nth($pair, 1);
			$color-invert: nth($pair, 2);
			&.has-tooltip-#{$name} {
				&.has-tooltip-bottom {
					&::after {
						border-color: transparent transparent rgba($color, $tooltip-background-opacity) transparent; } }
				&.has-tooltip-left {
					&::after {
						border-color: transparent transparent transparent rgba($color, $tooltip-background-opacity); } }
				&.has-tooltip-right {
					&::after {
						border-color: transparent rgba($color, $tooltip-background-opacity) transparent transparent; } }
				&:not(.has-tooltip-bottom),
				&:not(.has-tooltip-left),
				&:not(.has-tooltip-right) {
					&::after {
						border-color: rgba($color, $tooltip-background-opacity) transparent transparent transparent; } }
				&:before {
					background-color: rgba($color, $tooltip-background-opacity);
					color: $color-invert; } } }

		@include tooltip-hover {
			opacity: 1;
			visibility: visible; }

		&.has-tooltip-fade {
			@include tooltip-fade; } } }

@import 'responsiveness';
