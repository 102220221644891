$tooltip-arrow-size: 6px !default;
$tooltip-background-color: $grey-dark !default;
$tooltip-background-opacity: 0.9 !default;
$tooltip-color: $white !default;
$tooltip-font-family: $family-primary !default;
$tooltip-font-size: $size-7;
$tooltip-max-width: 15rem !default;
$tooltip-padding: .5rem 1rem !default;
$tooltip-radius: $radius-small !default;
$tooltip-z-index: 1020 !default;
