@mixin tooltip-hover {
	&:focus::before,
	&:focus::after,
	&:hover::before,
	&:hover::after,
	&.has-tooltip-active::before,
	&.has-tooltip-active::after {
		@content; } }

@mixin tooltip-arrow-top {
	&::after {
		top: 0;
		right: auto;
		bottom: auto;
		left: 50%;
		margin-top: $tooltip-arrow-size * -1 + 1;
		margin-right: auto;
		margin-bottom: auto;
		margin-left: $tooltip-arrow-size * -1 + 1;
		border-color: rgba($tooltip-background-color, $tooltip-background-opacity) transparent transparent transparent; } }

@mixin tooltip-box-top {
	&::before {
		top: 0;
		right: auto;
		bottom: auto;
		left: 50%;
		top: 0;
		margin-top: $tooltip-arrow-size * -1 + 1;
		margin-bottom: auto;
		transform: translate(-50%, -100%); } }

@mixin tooltip-top {
	@include tooltip-arrow-top;
	@include tooltip-box-top; }

@mixin tooltip-arrow-bottom {
	&::after {
		top: auto;
		right: auto;
		bottom: 0;
		left: 50%;
		margin-top: auto;
		margin-right: auto;
		margin-bottom: $tooltip-arrow-size * -1 + 1;
		margin-left: $tooltip-arrow-size * -1 + 1;
		border-color: transparent transparent rgba($tooltip-background-color, $tooltip-background-opacity) transparent; } }

@mixin tooltip-box-bottom {
	&::before {
		top: auto;
		right: auto;
		bottom: 0;
		left: 50%;
		margin-top: auto;
		margin-bottom: $tooltip-arrow-size * -1 + 1;
		transform: translate(-50%, 100%); } }

@mixin tooltip-bottom {
	@include tooltip-arrow-bottom;
	@include tooltip-box-bottom; }


@mixin tooltip-arrow-left {
	&::after {
		top: auto;
		right: auto;
		bottom: 50%;
		left: 0;
		margin-top: auto;
		margin-right: auto;
		margin-bottom: $tooltip-arrow-size * -1;
		margin-left: $tooltip-arrow-size * -2 + 1;
		border-color: transparent transparent transparent rgba($tooltip-background-color, $tooltip-background-opacity); } }

@mixin tooltip-box-left {
	&::before {
		top: auto;
		right: auto;
		bottom: 50%;
		left: $tooltip-arrow-size * -2 + 1;
		transform: translate(-100%, 50%); } }

@mixin tooltip-left {
	@include tooltip-arrow-left;
	@include tooltip-box-left; }


@mixin tooltip-arrow-right {
	&::after {
		top: auto;
		right: 0;
		bottom: 50%;
		left: auto;
		margin-top: auto;
		margin-right: $tooltip-arrow-size * -2 + 1;
		margin-bottom: $tooltip-arrow-size * -1;
		margin-left: auto;
		border-color: transparent rgba($tooltip-background-color, $tooltip-background-opacity) transparent transparent; } }

@mixin tooltip-box-right {
	&::before {
		top: auto;
		right: $tooltip-arrow-size * -2 + 1;
		bottom: 50%;
		left: auto;
		margin-top: auto;
		transform: translate(100%, 50%); } }

@mixin tooltip-right {
	@include tooltip-arrow-right;
	@include tooltip-box-right; }


@mixin tooltip-direction($direction) {
	@if $direction == 'top' {
		@include tooltip-top; }
	@else if $direction == 'right' {
		@include tooltip-right; }
	@else if $direction == 'bottom' {
		@include tooltip-bottom; }
	@else if $direction == 'left' {
		@include tooltip-left; } }
