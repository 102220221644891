#index-root {
  hr {
    width: 150px;
    display: block;
    margin: 1.5rem auto;
  }
  .hero {
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)),url('/assets/images/index/cover.jpg') center center / cover no-repeat;
    .hero-body {
      .container {
        text-align: center;

        .subtitle { font-size: 1.8rem; }
        .title {
          font-size: 5rem;
        }
        .body {
          font-size: 1.3rem;
          color: $white;
          strong {
            color: $white;
            font-size: 1.5rem;
            font-style: italic;
          }
        }
      }
    }

    @media screen and (max-width: 769px) {
      .hero-body {
        .container {
          text-align: center;

          .subtitle { font-size: 1.3rem; }
          .title {
            font-size: 2.5rem;
            line-height: 3.5rem;
          }
          .body {
            font-size: 1rem;
            color: $white;
            strong {
              color: $white;
              font-size: 1.1rem;
              font-style: italic;
            }
          }
        }
      }
    }
  }

  .section {
    text-align: center;
    padding: 5rem 1rem;

    &.is-light {
      background-color: $white-bis;
      hr {
        background-color: $white;
      }
    }

    &.is-white {
      background-color: $white;
    }

    &#about {
      background-color: $primary;
      .container {
        color: $white;
        .title {
          color: $white;
        }
      }
    }
    img {

      max-width: 80%;
      margin: 0 auto 2rem;
      padding: 0 2rem 0;

      @media screen and (max-width: 769px) {
        max-width: 100%;
      }
    }

    .container{
      max-width: 960px;
      p {
        width: 80%;
        margin: 0 auto 1.5rem auto;
        line-height: 2rem;
        @media screen and (max-width: 769px) {
          width: 100%;
        }
      }
    }
  }

  .supporters {
    list-style: none;

    display:-webkit-box;
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-flex;
    display:-moz-flex;
    display:flex;
    
    -webkit-box-lines:multiple;
    -moz-box-lines:multiple;
    -webkit-flex-wrap:wrap;
    -moz-flex-wrap:wrap;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;

    justify-content: center;

    .supporter {
      width: 25rem;
      img {
        width: 100%;
        padding: 0 1rem;
        margin: 0 0 .5rem 0;
        display: inline-block;
      }
      .name {
        font-size: 1.3rem;
      }
    }
  }

  .how-to-support-list {
    list-style: none;

    display:-webkit-box;
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-flex;
    display:-moz-flex;
    display:flex;
    
    -webkit-box-lines:multiple;
    -moz-box-lines:multiple;
    -webkit-flex-wrap:wrap;
    -moz-flex-wrap:wrap;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;

    justify-content: center;

    .how-to-support-item {
      width: 25rem;
      svg { margin: 0 0 1rem 0; color: $primary; }
      .text {
        color: #4a4a4a;
        font-size: 1.3rem;
      }
    }
  }

  #contact {
    .contact-form {
      .message {
        &.success-message {
          padding: .5rem 1rem;
          color: $white;
          background-color: $success;
        }
        &.error-message {
          padding: .5rem 1rem;
          color: $white;
          background-color: $danger;
        }
      }
      .warning {
        width: 100%;
        padding: .5rem 5rem;
        background-color: $warning;
      }

      form {
        margin: 0 auto;
        max-width: 80%;
        @media screen and (max-width: 769px) {
          max-width: 100%;
        }

        .label {
          text-align: left;
        }

        .field {
          margin: 0 0 1rem 0;
        }
        
        .error {
          text-align: left;
          margin: 0 1rem .5rem 0;
        }
      }
    }
  }
}